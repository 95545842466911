import AppRouter from './routing/index'
import './App.css'
import { useAuth0 } from '@auth0/auth0-react'
import GlobalSpinner from './components/Common/GlobalSpinner'
import { UserProvider } from './context/UserContext'
import { HelmetProvider } from 'react-helmet-async'

function App() {
  const { isLoading } = useAuth0()
  return isLoading ? (
    <GlobalSpinner />
  ) : (
    <HelmetProvider>
      <UserProvider>
        <AppRouter />
      </UserProvider>
    </HelmetProvider>
  )
}

export default App
