import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-logo">
        <img
          src="https://bimsualize.fra1.digitaloceanspaces.com/logo_bimsualize.png"
          alt="BIMsualize logo"
        />
        <span>Mejor integración ACC-PowerBi</span>
      </div>
      <div className="footer-col">
        <span>Sitio</span>
        <ul>
          <li>
            <NavLink to={'/app'}>App</NavLink>
          </li>
          <li>
            <NavLink to={'/precios'}>Precios</NavLink>
          </li>
          <li>
            <NavLink to={'/recursos'}>Recursos</NavLink>
          </li>
          <li>
            <NavLink to="/docs/category/primeros-pasos" target="_blank">
              Docs
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="footer-col">
        <span>Legal</span>
        <ul>
          <li>Privacidad</li>
          <li>Cookies</li>
          <li>Preferencias de uso</li>
          <li>© {new Date().getFullYear()} BIMsualize</li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
