import React from 'react'
import NavbarLat from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import { Button } from '@mui/material'
import Seo from '../components/Seo/Seo'
import FaqSchema from '../components/Seo/FaqSchema'
import './Home.css'
import useAuthRedirect from '../hooks/useAuthRedirect'

const Home = () => {
  const { handleLoginRedirect } = useAuthRedirect()

  const cta = 'Explora tus datos'
  const docs = 'Ver Documentación'

  const faqSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: '¿Qué hace BIMsualize?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Nuestra herramienta conecta tus datos de Autodesk Construction Cloud con Power BI para que puedas analizarlos, visualizarlos y tomar decisiones informadas en tiempo real.',
        },
      },
      {
        '@type': 'Question',
        name: '¿Necesito conocimientos técnicos para configurarla?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'No. Nuestra solución está diseñada para usuarios no técnicos. Solo necesitas credenciales de ACC y Power BI Desktop para comenzar.',
        },
      },
      {
        '@type': 'Question',
        name: '¿Se guardan mis datos en algún sitio?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'No. Nuestra solución tan sólo se encarga de que descargar los datos desde ACC a tu equipo sin pasar por nuestros servidores.',
        },
      },
      {
        '@type': 'Question',
        name: '¿Para qué empresas está pensada esta herramienta?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Empresas constructoras, estudios de arquitectura, ingenierías, promotoras y cualquier organización que use Autodesk Construction Cloud y quiera mejorar la gestión de datos y proyectos con Power BI.',
        },
      },
      {
        '@type': 'Question',
        name: '¿Puedo compartir los dashboards con otros usuarios de mi empresa?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Sí, desde Power BI puedes compartir informes con cualquier miembro de tu organización y/o terceros.',
        },
      },
      {
        '@type': 'Question',
        name: '¿Qué hago si tengo un problema o no veo mis datos?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Puedes contactarnos a través de soporte@atbim.io o consultar la base de conocimiento.',
        },
      },
    ],
  }

  const navigateToDocs = () => {
    window.open('docs/category/primeros-pasos/', '_blank')
  }

  return (
    <>
      <Seo
        title={'BIMsualize - Conecta Autodesk Construction Cloud con Power BI'}
        content={'website'}
        description={
          'Conecta Autodesk Construction Cloud (ACC) con Power BI en segundos. Visualiza y analiza tus datos de obra en tiempo real. Automatiza reportes, mejora la toma de decisiones y optimiza tus proyectos de construcción.'
        }
      />
      <FaqSchema faqSchema={faqSchema} />
      <NavbarLat />
      <main className="container-landing">
        <section id="visualizacion-datos-bim" className="section">
          <div className="frame-section-img">
            <img src="/assets/img/b5.webp" alt="Visualizacion datos BIM" />
          </div>
          <div className="frame-section-txt">
            <h1>Visualización de datos BIM</h1>
            <p>
              Los datos por sí solos no dicen nada si no sabes interpretarlos.
              Al conectar tus modelos BIM con herramientas de visualización como
              Power BI, puedes transformar grandes volúmenes de información en
              paneles visuales, claros y accionables, accesibles para todo el
              equipo.
            </p>
            <p>
              Potencia tu capacidad de análisis, mejora la toma de decisiones y
              convierte tus datos BIM en una herramienta estratégica para el
              éxito del proyecto.
            </p>
            <Button
              variant="contained"
              className="login-btn"
              onClick={handleLoginRedirect}
            >
              {cta}
            </Button>
          </div>
        </section>
        <section id="gestion-proyectos-bim" className="section">
          <div className="background-image-container">
            <img
              src="/assets/img/b3.webp"
              alt="Gestión proyectos BIM"
              className="background-img"
            />
            <div className="content-overlay">
              <h2>
                Gestión de proyectos BIM simplificada, sin perder el control
              </h2>
              <p>
                Mejora la calidad de tus proyectos con procesos BIM avanzados y
                saca el máximo partido a tus modelos de Revit, Navisworks o IFC.
                Descubre cómo automatizar, auditar y gestionar tus datos desde
                el origen, integrando todo tu flujo de trabajo en un entorno
                centralizado.
              </p>
              <div className="buttons-container">
                <Button
                  variant="contained"
                  className="login-btn"
                  onClick={handleLoginRedirect}
                >
                  {cta}
                </Button>
                <Button
                  variant="outlined"
                  className="login-btn login-btn--white"
                  onClick={navigateToDocs}
                >
                  {docs}
                </Button>
              </div>
            </div>
          </div>
        </section>
        <section id="resolucion-problemas" className="problems">
          <div className="problem">
            <div className="problem-img">
              <img src="/assets/img/b4.webp" alt="Visualizacion datos BIM" />
            </div>
            <div className="problem-txt">
              <span>01</span>
              <h2>Integra lo mejor de dos mundos: Autodesk (ACC) y Power BI</h2>
              <p>
                La integración entre Autodesk Construction Cloud (ACC) y
                Microsoft Power BI abre la puerta a una gestión de proyectos
                mucho más eficiente y orientada a datos. Al combinar la potencia
                de ACC como fuente central de información BIM con las
                capacidades de análisis y visualización de Power BI, puedes
                transformar los datos técnicos y de obra en paneles dinámicos y
                comprensibles para todo el equipo.
              </p>
            </div>
          </div>
          <div className="problem">
            <div className="problem-txt">
              <span>02</span>
              <h2>Combina BIM 4d, BIM 5d, BIM 6d en un único Dashboard</h2>
              <p>
                La evolución del BIM no se detiene en el modelado 3D. Con la
                integración de BIM 4D (tiempo), 5D (costes) y 6D (sostenibilidad
                y mantenimiento) en un único dashboard, puedes llevar la gestión
                de proyectos a otro nivel. Un dashboard bien diseñado con datos
                de BIM 4D, 5D y 6D te da una visión holística del proyecto,
                mejora la colaboración entre agentes y reduce el riesgo de
                errores en decisiones clave.
              </p>
            </div>
            <div className="problem-img">
              <img src="/assets/img/b1.webp" alt="Visualizacion datos BIM" />
            </div>
          </div>
          <div className="problem">
            <div className="problem-img">
              <img src="/assets/img/b2.webp" alt="Visualizacion datos BIM" />
            </div>
            <div className="problem-txt">
              <span>03</span>
              <h2>Enlaza tu proyecto BIM con tus datos personalizados</h2>
              <p>
                Tu modelo BIM no debería ser una isla. Cuando lo conectas con
                tus datos internos y específicos del proyecto —como proveedores,
                hitos, indicadores propios o información de cliente—, consigues
                una visión completa y personalizada que te permite tomar
                decisiones con total confianza. Ya no se trata solo de ver el
                modelo. Se trata de entenderlo en contexto, con la información
                que de verdad importa para tu empresa.
              </p>
            </div>
          </div>
        </section>
        <section className="faq-section">
          <div className="faq-container">
            <h2>Preguntas Frecuentes</h2>
            <p>
              Queremos que tengas claro cómo puedes visualizar y analizar tus
              modelos BIM con Power BI. Estas son las preguntas más frecuentes
              sobre nuestro servicio con Autodesk Construction Cloud y Power Bi.
            </p>
            <Button
              variant="outlined"
              className="login-btn login-btn--white"
              onClick={navigateToDocs}
            >
              {docs}
            </Button>
          </div>
          <div className="faq-container">
            {faqSchema.mainEntity.map((faq, index) => (
              <div className="faq" key={index}>
                <input type="checkbox" id={`faq${index}`} />
                <label htmlFor={`faq${index}`}>{faq.name}</label>
                <div className="answer">
                  <p>{faq.acceptedAnswer.text}</p>
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default Home
