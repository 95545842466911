import { Helmet } from 'react-helmet-async'

const Seo = ({ title, description, content, index = true }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content={content} />
      {!index && <meta name="robots" content="noindex, nofollow" />}
    </Helmet>
  )
}

export default Seo
