import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import { useAuth0 } from '@auth0/auth0-react'
import LoginButton from '../../auth0-config/login'

import AuthorizeButton from '../ModalAuthorize/AuthorizeButton'
import { UserContext } from '../../context/UserContext'
import DropdownUserLogin from './DropdownUserLogin'

const NavbarLat = () => {
  const { isAuthenticated } = useAuth0()
  const data = useContext(UserContext)

  return (
    <>
      <Navbar bg="light" expand="lg" className="sticky-top">
        <div
          style={{
            padding: '0 5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100vw ',
          }}
        >
          {/* TITULO Y LOGO */}
          <NavLink to="/" style={{ textDecoration: 'none' }}>
            <Navbar.Brand className="header-brand" to="/">
              <div className="header-logo-container">
                <img
                  src="https://bimsualize.fra1.digitaloceanspaces.com/logo_bimsualize.png"
                  alt="Branding Logo"
                />
              </div>
              <p className="header-brand__bim">BIM</p>
              <p className="header-brand__visualice">sualize</p>
            </Navbar.Brand>
          </NavLink>
          {/* OPCIONES DEL NAVBAR */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
            {/* <Nav.Link to="/">Home</Nav.Link> */}
            <NavLink
              style={{
                fontSize: '1.5rem',
                fontWeight: '600',
                fontFamily: 'Lato',
              }}
              to="/app"
            >
              App
            </NavLink>
            <NavLink
              style={{
                fontSize: '1.5rem',
                fontWeight: '600',
                fontFamily: 'Lato',
              }}
              to="/precios"
            >
              Precios
            </NavLink>
            <NavLink
              style={{
                fontSize: '1.5rem',
                fontWeight: '600',
                fontFamily: 'Lato',
              }}
              to="/recursos"
            >
              Recursos
            </NavLink>
            {/* <Button onClick={getAccessToken}>Prueba</Button> */}
            <NavLink
              style={{
                fontSize: '1.5rem',
                fontWeight: '600',
                fontFamily: 'Lato',
              }}
              to="/docs/category/primeros-pasos"
              target="_blank"
            >
              Docs
            </NavLink>

            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
            <NavDropdown.Item to="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item to="#action/3.2">
              Another action //{' '}
            </NavDropdown.Item>
          </NavDropdown> */}
          </div>
          {/* BOTON DE LOGIN/USUARIO Y FOTO DE PERFIL */}
          <div>
            {isAuthenticated ? (
              <div
                style={{
                  display: 'flex',
                  gap: '2rem',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <>
                  {data?.userAutodesk && (
                    <>
                      <AuthorizeButton />
                      <div
                        style={{
                          height: '30px',
                          width: '1px',
                          backgroundColor: '#c6c6c6',
                        }}
                      ></div>
                    </>
                  )}
                  <DropdownUserLogin trialEnd={data.endTrial} />
                </>
              </div>
            ) : (
              <LoginButton />
            )}
          </div>
        </div>
      </Navbar>
    </>
  )
}

export default NavbarLat
