import { Helmet } from 'react-helmet-async'

const FaqSchema = ({ faqSchema }) => {
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
    </Helmet>
  )
}

export default FaqSchema
